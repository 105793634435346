import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import FormGroup from '@material-ui/core/FormGroup';

import RadioGroup from '@material-ui/core/RadioGroup';



import FormLabel from '@material-ui/core/FormLabel';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


import MenuItem from '@material-ui/core/MenuItem';


import Select from '@material-ui/core/Select';

import Autocomplete from "@material-ui/lab/Autocomplete";

import InputLabel from '@material-ui/core/InputLabel';

import ReactLoading from 'react-loading';


import DateFnsUtils from '@date-io/date-fns';

import { withStyles } from '@material-ui/core/styles';

import DialogActions from "@material-ui/core/DialogActions";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";

import Switch from "@material-ui/core/Switch";



import MaterialTable from "material-table";

import FormControl from "@material-ui/core/FormControl";

import { green } from '@material-ui/core/colors';

import Radio from '@material-ui/core/Radio';

import { addYears, set, max } from "date-fns";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    color: 'white'
  },
  paper: {
    width: 1200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    margin: 'auto',
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
}));

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}


export default function Invoice() {
  const classes = useStyles();



  const [cc_columns, setCC_columns] = React.useState([
    { title: 'Date', field: 'date', editable: 'never' },
    { title: 'Cheque/ Transaction #', field: 'cc', editable: 'never' },
    { title: 'Amount', field: 'amount', type: 'numeric', editable: 'never' },
    { title: 'Remarks', field: 'remarks', editable: 'never' },
    { title: 'Mode', field: 'mode', editable: 'never' },
    { title: 'Shift ID', field: 'shift_id', editable: 'never' },
    { title: 'User ID', field: 'user_id', editable: 'never' },
    { title: 'User Name', field: 'user_name', editable: 'never' }
  ]);



  const [CC, setCC] = React.useState([]);


  const [saveInvoice, setSaveInvoice] = React.useState(false)

  const [total_net_cost, setTotalNetCost] = React.useState(0)


  const [cc_amount, setCCAmount] = React.useState(0)
  const [cc_date, setCCDate] = React.useState(new Date())
  const [cc_remarks, setCCRemarks] = React.useState('')
  const [cc_num, setCCNum] = React.useState('')

  const [Invoice_Total_Value, setInvoiceTotalValue] = React.useState(0)
  const [Discount, setDiscount] = React.useState(0)
  const [NetPayableValue, setNetPayableValue] = React.useState(0)

  const [Received_Payment, setReceived_Payment] = React.useState(0)
  const [Balance_Payment, setBalance_Payment] = React.useState(0)

  const [cash, setCash] = React.useState(true)
  const [credit_card, setCreditCard] = React.useState(false)
  const [dd, setDD] = React.useState(false)
  const [cheque, setCheque] = React.useState(false)
  const [invoice_id,setInvoiceID]=React.useState("")
  const [shift_id,setShiftID]=React.useState("")
    // 25-jul-2023 
    const [PreCount,setPreCount]=React.useState(-1)


  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">

      <div style={{
         marginLeft: 10,
         display: "flex",
         flexDirection: "row"
      }}>
      <TextField
            required
            id="outlined-required"
            label="Invoice ID"
            style={{
              borderRadius: 5,
            }}
            value={invoice_id}
            type="text"
            onChange={(e) => {
              setInvoiceID(e.target.value)
            }}
            variant="outlined"
          />

          <Button
            variant="contained"
            color="primary"
            style={{
              textAlign: 'center',
              fontSize: '1em',
              height: "2em",
            }}
            onClick={() => {

              var vSearchStr={
                invoice_id:invoice_id,
                user_id:localStorage.user_id
              }
          
              fetch("http://192.168.1.120:8090/4DACTION/WebInvoiceReceiptsCallsUpValue",{
                method:"POST",
                'Content-Type':"application/json",
                body: JSON.stringify(vSearchStr)
              }).then(res=>res.json()).then((response)=>{
                if(response.value_balance==0){
alert("Balance Not Due Against This Id")
return
                }
                console.log("WebInvoiceReceiptsCallsUpValue"+JSON.stringify(response))
                
                setBalance_Payment(response.value_balance)
                setReceived_Payment(response.value_received)
                setTotalNetCost(response.value_payable)
                setShiftID(response.shift_id)
                
                var ArrCC=[]
                JSON.parse(response.Arr_InV_REC_DATE).map((date,index)=>{
                  // 25-july-2023 Prev Count krny k kitny hn phr condiotion lgae ge
                 
                  setPreCount(index)
                  var obj = {
                    date: new Date(date).ddmmyyy(),
                    cc: JSON.parse(response.Arr_InV_REC_NO)[index],
                    amount: JSON.parse(response.Arr_InV_REC_VaLuE)[index],
                    remarks: JSON.parse(response.Arr_InV_REC_RemarKS)[index],
                    mode:JSON.parse(response.Arr_InV_REC_MODE)[index],
                    shift_id: JSON.parse(response.Arr_InV_REC_ShiFT_ID)[index],
                    user_id:JSON.parse(response.Arr_InV_REC_USER_ID)[index],
                    user_name:JSON.parse(response.Arr_InV_REC_USER_NAME)[index],
                  }
                  ArrCC.push(obj)
                })
            
                setCC([...ArrCC])
                setCCAmount(0)
                setCCRemarks("")
                setCCNum("")
          
              })

            }}
            className={classes.button}
          >Get Receiving Record</Button>

      </div>
        <div style={{
          marginLeft: 10
        }}>  <h2>Transactions Posting</h2></div>
        <div style={{
          marginLeft: 10,
          display: "flex",
          flexDirection: "row"
        }}>
          <Grid xs={3} sm={3} md={3} lg={3} >

            <FormGroup style={{
              marginLeft: "5em"
            }}>
              <b>Payment Method</b>
              <FormControlLabel
                control={<Checkbox checked={cash} onChange={(e) => {
                  setCash(e.target.checked)
                  if (e.target.checked) {
                    setCreditCard(false)
                    setDD(false)
                    setCheque(false)
                  }
                }} />}
                label="CASH"
              />
              <FormControlLabel
                control={<Checkbox checked={credit_card} onChange={(e) => {
                  setCreditCard(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setDD(false)
                    setCheque(false)
                  }
                }} />}
                label="Credit Card"
              />
              <FormControlLabel
                control={<Checkbox checked={dd} onChange={(e) => {
                  setDD(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setCreditCard(false)
                    setCheque(false)
                  }
                }} />}
                label="Demand Draft"
              />
              <FormControlLabel
                control={<Checkbox checked={cheque} onChange={(e) => {
                  setCheque(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setDD(false)
                    setCreditCard(false)
                  }
                }} />}
                label="Cheque"
              />
            </FormGroup>
          </Grid>
          <TextField
            required
            id="outlined-required"
            label="Amount"
            style={{
              borderRadius: 5,
            }}
            value={cc_amount}
            type="number"
            onChange={(e) => {
              setCCAmount(e.target.value)
            }}
            variant="outlined"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date"
              value={cc_date}
              onChange={(e) => {
                setCCDate(e)
              }}
              KeyboardButtonProps={{
                'aria-label': 'Date',
              }}
            />
          </MuiPickersUtilsProvider>
          {
            (cheque || credit_card || dd) ?
              <TextField
                required
                id="outlined-required"
                label="Cheque / Transaction #"
                style={{
                  borderRadius: 5,
                }}
                value={cc_num}
                onChange={(e) => {
                  setCCNum(e.target.value)
                }}
                variant="outlined"
              />
              : null
          }
          <span style={{
            display: 'flex',
            flexDirection: "column",
            marginTop: '-1em'
          }}>
            <b>Remarks</b>
            <TextareaAutosize
              rowsMax={4}
              style={{
                padding: '0.5em',
                height: '3em',
                marginLeft: "0.6em"
              }}
              value={cc_remarks}
              onChange={(e) => {
                setCCRemarks(e.target.value)
              }}
              placeholder="Transaction Remarks"
            /></span>

          <Button
            variant="contained"
            color="primary"
            style={{
              textAlign: 'center',
              fontSize: '1em',
              height: "2em"
            }}
            onClick={() => {
              if (total_net_cost == 0) {
                alert("Invoice Payable is Zero!!")
                return
              }
              if (cc_amount == 0 || cc_amount == "") {
                alert("Kindly Mention Received Amount !!")
                return
              }
              var diff = (total_net_cost - cc_amount) - Received_Payment
              if (diff < 0) {
                alert("Payable Amount Reached!!")
                return
              }
              var receive = parseInt(cc_amount) + parseInt(Received_Payment)
              var bal = parseInt(total_net_cost) - receive
              setReceived_Payment(receive)
              setBalance_Payment(bal)
              var obj = {
                date: cc_date.ddmmyyy(),
                cc: cc_num,
                amount: cc_amount,
                remarks: cc_remarks,
                mode: cash ? "CASH" : cheque ? "CHEQUE" : credit_card ? "CARD" : "DD",
                shift_id: shift_id,
                user_id: localStorage.getItem("user_id"),
                user_name: localStorage.getItem("name")
              }
              setCC([...CC, obj])
              setCCAmount(0)
              setCCRemarks("")
              setCCNum("")
            }}
            className={classes.button}
          >Add</Button>

        </div>
        <div>
          <Grid container>

            <Grid xs={9} sm={9} md={9} lg={9}>
              <MaterialTable
                title="Cheque / Card"
                columns={cc_columns}
                data={CC}
                style={{
                  marginLeft: 10
                }}
                editable={{
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...CC];
                        const index = oldData.tableData.id;
                         // 25-july-2023
                         console.log("count in index "+index)
                         console.log("count in prevCount "+PreCount)
 
                         // 25-july-2023 index jo delete kr rhy hn wo PreCount se less ya equal ho to delete na krny de
                         if(index<=PreCount){
                           resolve()
                           reject(new Error("Failed to fetch data"));
                           
                           alert("Do Not delete Previous Transaction.. !")
                          return
                         
                         }
                        dataDelete.splice(index, 1);
                        console.log(dataDelete)
                        var receive = parseInt(Received_Payment) - parseInt(oldData.amount)
                        var balance = parseInt(total_net_cost) - receive

                        setReceived_Payment(receive)
                        setBalance_Payment(balance)
                        setCC([...dataDelete]);
                        resolve();
                      }, 1000)
                    }),
                }}
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={{
              display: "flex",
              flexDirection: "column"
            }}>
              <TextField
                required
                id="outlined-required"
                label="RECEIVED"
                style={{
                  borderRadius: 5,
                }}
                value={Received_Payment}
                variant="outlined"
              />
              <TextField
                required
                id="outlined-required"
                label="BALANCE"
                style={{
                  borderRadius: 5,
                }}
                value={Balance_Payment}
                variant="outlined"
              />
              {saveInvoice ?
                <center style={{
                  textAlign: 'center',
                  marginLeft: '2em'
                }}>
                  <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                </center>
                : null}
              <Button
                variant="contained"
                color="primary"
                disabled={saveInvoice}
                style={{
                  textAlign: 'center',
                  width: "14.5em",
                  padding: "1em",
                  height: "4em",
                  marginLeft: "8px"
                }}
                onClick={async () => {
                  // WebInvoiceReceiptsPostingValue
                  var Arr_InV_REC_MODE = []
                  var Arr_InV_REC_DATE = []
                  var Arr_InV_REC_NO = []
                  var Arr_InV_REC_ShiFT_ID = []
                  var Arr_InV_REC_USER_ID = []
                  var Arr_InV_REC_USER_NAME = []
                  var Arr_InV_REC_REFUND = []
                  var Arr_InV_REC_RemarKS = []
                  var Arr_InV_AGainst_PreV_BalanCE = []
                  var Arr_InV_REC_VaLuE = []


                  var elements = []
                  var curr_date = ""

                 CC.map((item) => {
                    elements = item.date.split("/")
                    curr_date = elements[1] + "/" + elements[0] + "/" + elements[2]
                    var transaction_date = new Date(curr_date)
                    transaction_date.setHours(15)
                    Arr_InV_REC_DATE.push(transaction_date.toISOString())
                    Arr_InV_REC_MODE.push(item.mode)
                    Arr_InV_REC_NO.push(item.cc)
                    Arr_InV_REC_ShiFT_ID.push(item.shift_id)
                    Arr_InV_REC_USER_ID.push(item.user_id)
                    Arr_InV_REC_USER_NAME.push(item.user_name)
                    Arr_InV_REC_REFUND.push(false)
                    Arr_InV_REC_RemarKS.push(item.remarks)
                    Arr_InV_AGainst_PreV_BalanCE.push(false)
                    Arr_InV_REC_VaLuE.push(item.amount + "")
                    return item.mode
                  })

                  var vSearchStr={
                    invoice_id:invoice_id,
                    value_balance:Balance_Payment,
                    value_received:Received_Payment,
                    value_payable:total_net_cost,
                    Arr_InV_REC_MODE:Arr_InV_REC_MODE,
                    Arr_InV_REC_DATE:Arr_InV_REC_DATE,
                    Arr_InV_REC_VaLuE:Arr_InV_REC_VaLuE,
                    Arr_InV_REC_NO:Arr_InV_REC_NO,
                    Arr_InV_REC_ShiFT_ID:Arr_InV_REC_ShiFT_ID,
                    Arr_InV_REC_USER_ID:Arr_InV_REC_USER_ID,
                    Arr_InV_REC_USER_NAME:Arr_InV_REC_USER_NAME,
                    Arr_InV_REC_RemarKS:Arr_InV_REC_RemarKS
                  }
              
                  fetch("http://192.168.1.120:8090/4DACTION/WebInvoiceReceiptsPostingValue",{
                    method:"POST",
                    'Content-Type':"application/json",
                    body: JSON.stringify(vSearchStr)
                  }).then(res=>res.json()).then((response)=>{
                    console.log(response)
                    if(response.Invoice_Status=="Successful"){
                      alert("Posting Successfully Done !!")
                      window.location.href="/"
                    }
              
                  })
    
                }}
              >Save Invoice</Button>
            </Grid>

          </Grid>
        </div>


      </form>
      <br />
      <br />
      <div style={{ width: "100%" }}>

      </div>
      <br />
      <br />
      <br />
      <br />
      <br />


    </div>
  );
}