import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';
import Slide from "@material-ui/core/Slide";
import PrintIcon from '@material-ui/icons/Print';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import ReplyTwoToneIcon from '@material-ui/icons/ReplyTwoTone';

import SearchIcon from '@material-ui/icons/Search';
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from "material-table";
//for payment method
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from '@material-ui/core/FormGroup';
//for Remarks Text Area
import TextareaAutosize from '@material-ui/core/TextareaAutosize';





const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 1000,
    },
});


export default function Lab_Tests() {
    const classes = useStyles();
    var date = new Date()
    var temp_role = localStorage.getItem('role')
    // date.setDate(date.getDate() - 2);
    date.setDate(date.getDate()-1);

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [width, height] = [window.innerWidth, window.outerWidth];
    //31-jan-2023
    const [panelCode, setPanelCode] = React.useState('')
    const [panelName, setPanelName] = React.useState('')

  const [panelList,setPanelList]=React.useState([])
    const [loading, setLoading] = React.useState(true)
    //for MaterialTable
  const [SelectionControl,setSelectionControl]=React.useState(true)
  //for total Amount
  const [total_cost, setTotal_cost] = React.useState(0)
  //for Payment Method
  const [cash, setCash] = React.useState(true)
  const [online, setOnline] = React.useState(false)
  const [credit_card, setCreditCard] = React.useState(false)
  const [dd, setDD] = React.useState(false)
  const [cheque, setCheque] = React.useState(false)
  //for Remarks 
  const [remarks,setremarks]=React.useState("")
  //for deiscount
  const [discount, setdiscount] = React.useState(0)
  const [netValue, setnetValue] = React.useState(0)
  const [transactionId, settransactionId] = React.useState('')
  //for invoices record
  const [totalinvoice,settotalinvoces]=useState()
  //for invoice search seprate
  const [invoiceID, setInvoiceID] = React.useState("")
  
  // Print Indicator
  const [printIndicator, setPrintiDicator] = React.useState(false)
  // 2nd table Show that contain total invoice that are selected from table one 
  const [selectedValue, setSelectedValue] = React.useState('Normal');

  const [secTableShow, setsecTableShow] = React.useState(false)
//for invalid invoice 
const [Invalid, setInvalid] = React.useState(false)
 //for shift id in useEffect
 const [shiftId, setshiftId] = React.useState("")


// for editable table
const [columns, setColumns] = React.useState([
  { title: 'Invoice ID', field: 'id', editable: 'never' },
  // { title: 'Title', field: 'title' },
// Show detail of test 17-dec-2022
  // this field comment before 17-dec
  { title: 'Date', field: 'date' }, 
  // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha

  { title: 'Patient Name', field: 'PatientName', editable: 'never' },
  {
    title: 'Invoice Balance', field: 'payable',  editable: 'never', 
  },
  {
title: 'Received Value', field: 'receivedValue', type: 'numeric', editable: selectedValue.includes('Normal') ? "onUpdate" : "never", render: rowData => {
      return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.receivedValue }</p>
    }
  },
  
]);





  // const [rows,setRows]=React.useState([{ id: 11223343,date:"03/11/21",PatientName:"Muhammad Ilyas",mobile:"03418866884",payable:10000 },{ id: 11223344,date:"03/11/21",PatientName:"Muhammad Ilyas",mobile:"03418866884",payable:5000},{ id: 11223345,date:"03/11/21",PatientName:"Muhammad Ilyas",mobile:"03418866884",payable:20000 },{ id: 11223345,date:"03/11/21",PatientName:"Muhammad Ilyas",mobile:"03418866884",payable:20000 },{ id: 11223345,date:"03/11/21",PatientName:"Muhammad Ilyas",mobile:"03418866884",payable:20000 },])
  const [rows, setRows] = React.useState([])
  

  



    useEffect(() => {
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "http://192.168.1.120:3000"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "http://192.168.1.120:3000"
        }
        var vSearchStr={
          "User_ID" :localStorage.getItem('user_id'),
          "Menu_Option" :"Overall Create Booking"
      }
      fetch("http://192.168.1.120:8090/4DACTION/WebCheckUserStatusActiveAccess",{
        method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify(vSearchStr)
      }).then(res=>res.json()).then((res)=>{
        console.log(res)
  
        if(res[0].Option_Status=="Authorized"){
          var obj={
            Branch_ID:localStorage.getItem('branch_id'),
            User_ID:localStorage.getItem('user_id')
          }
          fetch('http://192.168.1.120:8090/4DACTION/Web_InV_ShiftsClosingCallsUp',
          {
            method: "POST",
            body: JSON.stringify(obj)
          }).then(res=>res.json()).then((response)=>{
            console.log("Web_InV_ShiftsClosingCallsUp"+JSON.stringify( response))
            if(response[0].Status=="No Record Found"){
              alert("No Shift Found!!")
              window.location.href="http://192.168.1.120:3000"
            }
            setshiftId(response[0].Shift_ID)
            // setState(response[0])
          })
        
       
          
        }else{
          localStorage.clear()
          window.location.href = "http://192.168.1.120:3000"
        }
  
      })
        // fetch("http://192.168.1.120:8090/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        //     setBranches(response)

        // })
        var obj22={
            branch_id:localStorage.getItem("branch_id")
          }
        fetch("http://192.168.1.120:8090/4DACTION/WebPortal_GetPanelsListBranch",
  // fetch("http://206.84.161.44:8090/4DACTION/WebPortal_GetPanelsListBranch", 

        {
               method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify(obj22)
        }
        ).then((res) => res.json()).then((response) => {
            console.log("GetPanelsListBranch"+JSON.stringify(response))
            // if(response.cred)
          response.push({
            Panel_Code: "Normal",
            Panel_Title: "Normal Patient"
          })
          setPanelList(response)
         
        })
       
        setLoading(false)


    }, [])
    Date.prototype.hhmm = function () {
        var mm = this.getMinutes();
        var hh = this.getHours();

        return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
        ].join(':');
    };

    Date.prototype.ddmmyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            this.getFullYear()
        ].join('/');
    };
//my work 28-jan-2023
const SearchHandler=()=>{
    // alert("Date From "+from.toISOString()+"Date To"+to.toISOString()+"Panel"+panel)
    console.log("Date From "+from.toISOString()+"Date To"+to.toISOString()+"Panel Code"+panelCode)
    //11-april-2023 jab search kre to purani value zero kr de aur second table show na kre
    setsecTableShow(false)
    setTotal_cost(0)
                 setnetValue(0)
    

    if(panelCode){
 // panel_code:"AR-01082",
    var obj22={
      panel_code:panelCode,
      date_starting:from.toISOString(),
      date_ending:to.toISOString()

    }
  fetch("http://192.168.1.120:8090/4DACTION/Receipt_PanelsInvoicesCallsUpWS",{
  // fetch("http://206.84.161.44:8090/4DACTION/Receipt_PanelsInvoicesCallsUpWS", {

  
         method:"POST",
  'Content-Type':"application/json",
  body: JSON.stringify(obj22)
  }
  ).then((res) => res.json()).then((response) => {
    console.log("response Line 236 Receipt_PanelsInvoicesCallsUpWS ",response)

    console.log("response Line 236 Receipt_PanelsInvoicesCallsUpWS ",response.Array_invoice_no.length)
    if(response.Array_invoice_no=="[]"){
      alert("No data found")
    }

      var counter = 0
      
      var collectionBox = []


      var data = response
      JSON.parse(data.Array_invoice_no).map((invoiceNo) => {
        console.log("data ArrayAuxId with map Line 1360 Printing",invoiceNo)
        var temp = {
          id: JSON.parse(data.Array_invoice_no)[counter],
          date: new Date(JSON.parse(data.Array_invoice_date)[counter]).getDate()-1 + "/" +(new Date(JSON.parse(data.Array_invoice_date)[counter]).getMonth()+1)+ "/" + new Date(JSON.parse(data.Array_invoice_date)[counter]).getFullYear(),
          PatientName: JSON.parse(data.Array_invoice_patient)[counter],
          mobile: JSON.parse(data.Array_invoice_mobile)[counter],
         payable: JSON.parse(data.Array_invoice_oustanding)[counter],
        }
        counter++
        collectionBox.push(temp)
      
      })
      console.log("Collection  box value is 1385################################ printing",collectionBox)
setRows(collectionBox)   
  })
}
else{
  alert("Please Select Panel")

}

}
const SingleInvoiceSearchHandler=()=>{
     console.log("Invoice ID"+invoiceID)
     //ye wala working me tha without api integration
    //  var obj={
    //   id: 11223350,date:"03/11/21",PatientName:"Muhammad Ilyas",mobile:"03418866884",payable:10000
    // }
    
    // var all_test = [...rows, obj]

    // Promise.all(all_test).then((values) => {
    //   console.log("######",values)
    //   setRows(values)
    // })
     //ye wala working me tha without api integration yahan tak

  // setRows(obj)
  var collectionBox = []

// 149552
    var obj = {
      invoice_id: invoiceID,
    }
     fetch("http://192.168.1.120:8090/4DACTION/ReceiptGenInvoiceInfoByIDCallUp", {
  // fetch("http://206.84.161.44:8090/4DACTION/ReceiptGenInvoiceInfoByIDCallUp", {
      method: "POST",
      body: JSON.stringify(obj)
    }).then(res => res.json()).then((response) => {
      console.log("SingleInvoice Call "+JSON.stringify( response))
      if(response.invoice_status=="No Invoice Found"){
        setInvalid(true)
      }else{
        if(response.invoice_status=="Invoice Outstanding"){
      var temp = {
        id: response.invoice_id,
         date: new Date(response.invoice_date).getDate()-1 + "/" +(new Date(response.invoice_date).getMonth()+1)+ "/" + new Date(response.invoice_date).getFullYear(),
        PatientName: response.invoice_patient,
        mobile: "",
       payable: JSON.parse(response.invoice_outstanding),
      }
      // collectionBox.push(temp)
    var all_test = [...rows, temp]


      console.log("check temp "+JSON.stringify( temp))
      Promise.all(all_test).then((values) => {
      console.log("######",values)
      setRows(values)
    })
      // setRows(...rows ,collectionBox)
    //   if (response[0].ArrayAuxID == "[]") {
    //     setInvalid(true)
    //     return
    //   }
    //   setInvalid(false)
    //   var data = response[0]

    //   var collectionBox = []

    //   var counter = 0

    //   var month= ["JAN","FEB","MAR","APR","MAY","JUN","JULY","AUG","SEP","OCT","NOV","DEC"]
    //   var counterData=0

    //   JSON.parse(data.ArrayAuxID).map((gRidRow) => {
        
    //     if(JSON.parse(data.ArrayCallsBlobPDF)[counter]){
    //       window.open('http://192.168.1.120:8090/4DACTION/GetReportPDF/' + JSON.parse(data.ArrayAuxID)[counter] )
    //       counterData=1
    //       return
    //     }
    //     var temp = {
    //       AuxId: gRidRow,
    //       patientID: JSON.parse(data.ArrayPatientID)[counter],
    //       id: JSON.parse(data.ArrayInvoiceNo)[counter],
    //       date: new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getDate() + "-" +(month[new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getMonth()])+ "-" + new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getFullYear(),
    //       name: JSON.parse(data.ArrayPatientName)[counter],
    //       mobile: JSON.parse(data.ArrayPatientMobile)[counter],
    //       test_code: JSON.parse(data.ArrayTestCode)[counter],
    //       test_name: JSON.parse(data.ArrayTestName)[counter],
    //       department: JSON.parse(data.ArrayDepartmentID)[counter],
    //       nature: JSON.parse(data.ArrayTestNature)[counter],
    //       departmentName: JSON.parse(data.ArrayDepartmentName)[counter],
    //       alloWprint:!JSON.parse(data.ArrayPrintingNOTAllow)[counter],
    //       // For print Image
    //       groupId: JSON.parse(data.ArrayGroupID)[counter], 
    //     }
    //     counter++
    //     collectionBox.push(temp)
    //   })
    //   if(counterData==0){
    //     setRows(collectionBox)
    //     setToprint({})
    //     setPrintiDicator(false)
    //   }else{

    //   }

    setInvalid(false)
        }else{
          alert("Outstanding Balance not found againts this invoice")
        }
      }
    })

}
const handleChange = (event) => {
  console.log("chkkkkkkk"+JSON.stringify( event.target.value))
  setPanelCode(event.target.value.Panel_Code)
  setPanelName(event.target.value.Panel_Title)
    // setPanel(event.target.value.Panel_Code)
  }
  const SeveRecordHandler=()=>{
    // alert("Date From "+from.toISOString()+"Date To"+to.toISOString()+"Panel"+panel)
    // console.log("totalCost"+total_cost+"NetValue"+netValue+"invoices data"+JSON.stringify( totalinvoice))
    if(total_cost){
      if(netValue){
    var d = new Date()
    var strDate=d.toISOString()
    console.log(strDate)
    var ArrayInvoiceNo=[]
var ArrayInvoiceOutstanding=[]
var ArrayInvoiceReceived=[]
for(let i=0;i<totalinvoice.length;i++){
          console.log(totalinvoice[i])
          ArrayInvoiceNo.push(totalinvoice[i].id)
          ArrayInvoiceOutstanding.push(totalinvoice[i].payable)
          ArrayInvoiceReceived.push(totalinvoice[i].receivedValue)
}

//         {totalinvoice?.map((item,index)=>{
//           console.log(item)
// //  ArrayProductId.push(item.system_id)[index]
// //  ArrayProductQty.push(item.requestedQuantity)[index]
// //  ArrayProductPrice.push(item.price_Offered)[index]
//         })}
 // panel_code:"AR-01082",
      // panel_name:"Aamir Clinical Lab",
   console.log("panel code orignal"+panelCode+"panil name"+panelName)
    console.log({
      user_shift:shiftId,
      date_receipt:strDate,
      panel_code:panelCode,
      panel_name:panelName,
      amount_rec:total_cost,
      disscount_adj:parseInt(discount),
      net_value:parseInt(netValue),
      transaction_id:transactionId,
      pay_mode: cash ? "CASH" : cheque ? "CHEQUE" : credit_card ? "CARD" :online?"ONLINE": "DD",
      date_starting:from.toISOString(),
      date_ending:to.toISOString(),
      remarks:remarks,
      Array_Invoice_no:ArrayInvoiceNo,
      Array_Invoice_outstanding:ArrayInvoiceOutstanding,
      Array_Invoice_received:ArrayInvoiceReceived
    })
    var obj = {
      user_shift:shiftId,
      date_receipt:strDate,
      panel_code:panelCode,
      panel_name:panelName,
      amount_rec:total_cost,
      disscount_adj:parseInt(discount),
      net_value:parseInt(netValue),
      transaction_id:transactionId,
      pay_mode: cash ? "CASH" : cheque ? "CHEQUE" : credit_card ? "CARD" :online?"ONLINE": "DD",
      date_starting:from.toISOString(),
      date_ending:to.toISOString(),
      remarks:remarks,
      Array_Invoice_no:ArrayInvoiceNo,
      Array_Invoice_outstanding:ArrayInvoiceOutstanding,
      Array_Invoice_received:ArrayInvoiceReceived
    }
      fetch("http://192.168.1.120:8090/4DACTION/ReceiptLaterAgainstInvPostingWS", {
  // fetch("http://206.84.161.44:8090/4DACTION/ReceiptLaterAgainstInvPostingWS", {
    method: "POST",
    body: JSON.stringify(obj)
  }).then(res => res.json()).then((response) => {
    console.log(" Submit ReceiptLaterAgainstInvPostingWS Call "+JSON.stringify(response))
    if(response.receipt_no!=""){
      refreshPage()
      alert("Save Record Successfully")
      
    }
  
  })
  
  }else{
    alert("NetValue cannot be zero ")
  }
  }else{
    alert("Amount cannot be zero ")
  }

}
  
const refreshPage=()=> {
  window.location.reload(false);
}


    return (
        <>
            <Paper className={classes.root}>
                <GridContainer style={{ padding: '2em'}}>
                    <GridItem xs={6} sm={6} md={6} lg={3}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="dd/MM/yyyy"
                                value={from}
                maxDate={to}

                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFrom(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                    <GridItem xs={6} sm={6} md={6} lg={3}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="dd/MM/yyyy"
                                value={to}
                               maxDate={new Date()}

                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setTo(date)}
                            />
                        </MuiPickersUtilsProvider>

                    </GridItem>
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                    {
                        localStorage.getItem('org_branch_id') == '0' ?
                            <GridItem xs={12} sm={6} md={6} lg={3}  >
          <Autocomplete
            id="country-select"
            options={panelList}
            classes={{
              option: classes.option
            }}
            autoHighlight
             onChange={(event, newValue) => {
              if(newValue!=null){

                handleChange({
                  target:{
                    value:newValue
                  }
                })
                
              }else{
                handleChange({
                  target:{
                    value:"Normal"
                  }
                })
              }
            }}
            getOptionLabel={(option) => option.Panel_Title}
            style={{
              // width:"10em"
            }}
            renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Panel"
                variant="outlined"
                style={{
                  width:"15em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
                    {/* </Grid> */}
                            </GridItem>
                            : null
                    }
                    <GridItem xs={12} sm={6} md={6} lg={3} style={{ margin: 'auto', display:"flex",justifyContent:"center",alignItem:"center"}} >

                        <Button style={{marginLeft:15}} variant="contained" color="primary" disableElevation disabled={loading}  onClick={() => {
            
          SearchHandler()
         
        }}

                        >
                            Search<SearchIcon />
                        </Button>
              

                    </GridItem>
                </GridContainer>
                {/* <div style={{
        flexDirection: "row",
        flex: 1,
        textAlign: 'center',
        padding: "2em"
      }}> */}
        <GridContainer style={{ paddingBottom: '2em',justifyContent:"center",alignItem:"center",display:"flex"}}>
        
        <TextField
          required
          id="outlined-required"
          label="Invoice No"
          variant="outlined"
          value={invoiceID}
          onChange={(e) => {
            // setRows([])
            // setPrintiDicator(false)
            setInvalid(false)
            
            setInvoiceID(e.target.value)
          }}
        />

        <Button
          variant="contained"
          color="primary"
          style={{
            margin: '1em'
          }}
          onClick={() => {
            SingleInvoiceSearchHandler()
          }}
        >Search <SearchIcon /></Button>

      
      {/* </div> */}
      </GridContainer>
      {
        Invalid ?
          <p style={{
            textAlign: "center",
            color: "red",
            marginTop:"-1%",
            marginRight:"13%"
          }}>No Pending Invoice Found ! </p>
          : null
      }
      <div style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-evenly"
      }}>
      {
        printIndicator ?
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={secTableShow? <ReplyTwoToneIcon fontSize={"large"} />:<DoneAllTwoToneIcon fontSize={"large"} />}
            onClick={() => {
              if(secTableShow)
              setsecTableShow(false)
              else
               setsecTableShow(true)
            }}

          >{secTableShow?"Go Back": "Accumulate"}</Button>
          :
          null
      }
     
      </div>


                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                        <GridContainer>
                     
                          <GridItem md={9} style={{}} >
                            {secTableShow?
                              <MaterialTable
                              title="Selected Receipt Invoices"
                              columns={columns}
                              data={totalinvoice}
                              style={{
                                marginLeft: 10
                              }}
                              maxBodyHeight={"50em"}
                              editable={{
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                      const dataUpdate = [...totalinvoice];
                                      console.log("dataUpdate"+JSON.stringify( dataUpdate))
                                      const index = oldData.tableData.id;
                                      console.log("index"+index)
                                      console.log("NewData"+JSON.stringify(newData))
                                      if(newData.receivedValue<0){
                                        resolve();
                                        alert("Recieved Value cannot be Negative")
                                       
                                        return
                                      }
                                      if(newData.receivedValue>newData.payable){
                                        resolve();
                                        alert("Invoice Balance is "+newData.payable+" and Received value is "+newData.receivedValue+"Please Enter Correct value")
                                        
                                        return
                                      }

                                        // dataUpdate[index].receivedValue = newData.receivedValue;
                                          dataUpdate[index] = newData;
                                          settotalinvoces([...dataUpdate]);
                                          // console.log(totalinvoice)

                                        // settotalinvoces(dataUpdate)
                                      // if (oldData.title != newData.title && (oldData.Test_ID.includes('SRV'))) {
                                      //   dataUpdate[index].title = newData.title;
                                      //   setData([...dataUpdate]);
                                      // }
                                    //  Update Require
                                     
                                      
                                      // if (newData.discount_percent != oldData.discount_percent) {
                                      //   if (newData.discount_percent > 100) {
              
                                      //   } else {
                                      //     var dc_value = (newData.cost / 100) * parseInt(newData.discount_percent)
              
                                      //     var net_cost = (newData.cost - dc_value) - parseInt(newData.special_discount)
              
                                      //     if (net_cost < 0) {
                                      //       alert("More Disocunt can't applied!")
              
                                      //       resolve();
                                      //       return
                                      //     }
              
                                      //     newData.discount_value = dc_value
                                      //     newData.net_cost = net_cost
              
                                      //     dataUpdate[index] = newData;
                                      //     console.log("2911"+JSON.stringify(dataUpdate[index]))
                                      //     console.log("2911"+JSON.stringify( newData))
                                      //      setData([...dataUpdate]);
                                      //   }
                                      // }
              
                                      var newNetVal= 0
                                    //   var discount_value = 0
                                    //   var sp_disc = 0
                                    //   var net_cost = 0
                                      var temp = await dataUpdate.map((item) => {
                                        // console.log("2920"+item)
                                        // newNetVal = cost + item.receivedValue
                                        // discount_value = discount_value + item.discount_value
                                        // sp_disc = sp_disc + item.special_discount
                                        return newNetVal = newNetVal + item.receivedValue 
                                      })
                                      Promise.all(temp).then(() => {
                                        setnetValue(newNetVal)
                                      
                                      })
              
              
              
              
                                      resolve();
                                    }, 1000)
                                  }),
                               
                                
                              }}
                            />
                            :
                          <MaterialTable
                          style={{marginLeft:25}}
        title="Payment Receipt Mutiple Invoices "
        data={rows}
        columns={[
          { title: 'Invoice ID', field: 'id' },
          { title: 'Date', field: 'date' },
          {title: 'Patient Name', field:"PatientName"},
          { title: 'Mobile', field: 'mobile' },
          { title: 'Payable', field: 'payable' }
        ]}
        // maxBodyHeight={"50em"}onsele

        options={{
          selection: true,
          paging:true,
          minBodyHeight:40,
          showSelectAllCheckbox:SelectionControl
        }}

        onSelectionChange={(orderToPrint) => {
          if (orderToPrint.length == 0) {
            setPrintiDicator(false)
            // 11-april-2023 jab koi b select na ho item  value zero kr de aur second table k data khtm kr de
            setTotal_cost(0)
                 setnetValue(0)
                settotalinvoces()

             return
                                    }
            setPrintiDicator(true)
            console.log(orderToPrint)
            var cost = 0
            var netVal = 0
          var inVoice=[]

            

            var temp = orderToPrint.map((item) => {
               
                        // var discount_value = 0
                        // var sp_disc = 0
                        // var net_cost = 0
                console.log("2920"+JSON.stringify( item))
                cost = cost + item.payable
                netVal=netVal+item.payable
            // inVoice.push(item)

            inVoice.push({id:item.id,PatientName:item.PatientName,date:item.date,mobile:item.mobile,payable:item.payable,receivedValue:item.payable})


                //yhan pe item pe pora object aa rha hai ye b aik array me dalna hai jis se jo jo item select hon ge wo 4d me deny hn
                // discount_value = discount_value + item.discount_value
                // sp_disc = sp_disc + item.special_discount
                // return net_cost = net_cost + item.net_cost
              })
              Promise.all(temp).then(() => {
                setTotal_cost(cost)
                 setnetValue(netVal)
                settotalinvoces(inVoice)
                // setTotalDiscountValue(discount_value)
                // setTotalSpecialDiscount(sp_disc)
                // setTotalNetCost(net_cost)
                // setCC([])
                // setBalance_Payment(net_cost)
                // setReceived_Payment(0)
              })
        //   if (orderToPrint.length == 0) {
        //     setPrintiDicator(false)
        //     return
        //   }
        //   setPrintiDicator(true)
          
          
        //   var inVoice=[]
        //   var AuXiD=[]
        //   var departments=[]
        //   var date=[]
        //   var covidAux=""
        //   var covidAntiGen=""
        
        //   var departNames=[]
        //   var AllowPrint=[]
        //   orderToPrint.map((item)=>{
        //     AllowPrint.push(item.alloWprint)
        //     AuXiD.push(item.AuxId)
        //     inVoice.push(item.id+"")
        //     departments.push(item.department)
        //     date.push(item.date)
        //     departNames.push(item.departmentName)
        //     if(item.test_code=="COVIDPCR"){
        //       covidAux=item.AuxId
        //     }
        //     if(item.test_code=="COVAG"){
        //       covidAntiGen=item.AuxId
        //     }
        //   })
          
        //   setToprint({
        //     AllowPrint:AllowPrint,
        //     inVoiceID:inVoice,
        //     auXiD:AuXiD,
        //     department:departments,
        //     date:date,
        //     departNames:departNames,
        //     covidAux:covidAux,
        //     covidAntiGen:covidAntiGen
        //   })
        }}
      />
    }
                          </GridItem>
                          
                          <GridItem md={3} style={{}}>
                          <TextField
            required
            id="outlined-required"
            label="Amount"
            style={{
              borderRadius: 5,
              marginTop:6,
              marginLeft:5
            }}
            value={total_cost}
            type="number"
           
            variant="outlined"
          />
             <TextField
            // required
            id="outlined-required"
            label="Discount/Adjustment"
            style={{
              borderRadius: 5,
              marginTop:10,
              marginLeft:5
            }}
            value={discount}
            // type="number"
            onChange={(e) => {
                setdiscount(e.target.value)
        //         if(netValue>0){
        //    var total= ((discount)/(100))*netValue
        //    setnetValue(netValue-total)
        //     }
              }}
           
            variant="outlined"
          />
          {discount>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em"
                }}
                onClick={
                  async () => {
                    if(total_cost){
                      if(netValue){
                    let total =netValue-discount
                         setnetValue(total)}
                         else{
                          alert("Please Enter Received Value")
                         }
                    }
                    else{
                      alert("Please select Invoices ")
                      setdiscount(0)
                    }






                  }
                }>Apply</Button>}
           

             <TextField
            required
            id="outlined-required"
            label="Net Value"
            style={{
              borderRadius: 5,
              marginTop:10,
              marginLeft:5
            }}
            value={netValue}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              setnetValue(e.target.value)
            }}

          />
               <TextField
            required
            id="outlined-required"
            label="Transaction ID"
            style={{
              borderRadius: 5,
              marginTop:10,
              marginLeft:5
            }}
            value={transactionId}
             type="text"
            onChange={(e) => {
                settransactionId(e.target.value)
              }}
           
            variant="outlined"
          />
                          <FormGroup style={{
               marginLeft: 5,
               marginTop:10

            }}>
                
              <b>Payment Method</b>
              <FormControlLabel
                control={<Checkbox checked={cash} onChange={(e) => {
                  setCash(e.target.checked)
                  if (e.target.checked) {
                    setCreditCard(false)
                    setDD(false)
                    setCheque(false)
                    setOnline(false)
                  }
                }} />}
                label="CASH"
              />
               <FormControlLabel
                control={<Checkbox checked={online} onChange={(e) => {
                  setOnline(e.target.checked)
                  if (e.target.checked) {
                    setCreditCard(false)
                    setDD(false)
                    setCheque(false)
                    setCash(false)

                  }
                }} />}
                label="Online"
              />
              <FormControlLabel
                control={<Checkbox checked={credit_card} onChange={(e) => {
                  setCreditCard(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setDD(false)
                    setCheque(false)
                    setOnline(false)

                  }
                }} />}
                label="Credit Card"
              />
              <FormControlLabel
                control={<Checkbox checked={dd} onChange={(e) => {
                  setDD(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setCreditCard(false)
                    setCheque(false)
                    setOnline(false)

                  }
                }} />}
                label="Demand Draft"
              />
              <FormControlLabel
                control={<Checkbox checked={cheque} onChange={(e) => {
                  setCheque(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setDD(false)
                    setCreditCard(false)
                    setOnline(false)

                  }
                }} />}
                label="Cheque"
              />
            </FormGroup>
            {/* <span style={{
            display: 'flex',
            flexDirection: "column",
            marginTop: 6
          }}>
            <b>Remarks</b>
            <TextareaAutosize
              rowsMax={4}
              style={{
                padding: '0.5em',
                height: '3em',
                marginLeft: 1
              }}
              value={remarks}
              onChange={(e) => {
                setremarks(e.target.value)
              }}
              placeholder="Transaction Remarks"
            /></span> */}
           
{/* {total_cost} */}
                          </GridItem>

                        </GridContainer>
                        <GridContainer>
                     
                          <GridItem md={4}>
                          <span style={{
            display: 'flex',
            flexDirection: "column",
            marginTop: 6,
            marginLeft:25
          }}>
            <b>Remarks</b>
            <TextareaAutosize
              rowsMax={4}
              style={{
                padding: '0.5em',
                height: '3em',
                marginLeft: 1
              }}
              value={remarks}
              onChange={(e) => {
                setremarks(e.target.value)
              }}
              placeholder="Transaction Remarks"
            /></span>
                          </GridItem>
                          <GridItem md={4}>
                          <Button
                variant="contained"
                color="primary"
                onClick={() => {
            
                  SeveRecordHandler()
                 
                }}
        
                // disabled={saveInvoice}
                style={{
                  textAlign: 'center',
                  width: "14.5em",
                  padding: "1em",
                  height: "4em",
                  marginLeft: "8px",
                  marginTop:25,
                  marginBottom:10
                }}>
                    Save Record
                </Button>
                          </GridItem>
                          </GridContainer>
                    </>
                }


            </Paper>


        </>
    );
}
