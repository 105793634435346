
import Invoice from './Invoice/invoice'
import AppsIcon from '@material-ui/icons/Apps';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';


import AssessmentIcon from '@material-ui/icons/Assessment';
// Icons

import DvrIcon from '@material-ui/icons/Dvr';

import ReceiptIcon from '@material-ui/icons/Receipt';

import AddPatient from './AddPatient/AddPatient.js'
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import Lab_Tests from './Lab_Reports/Lab_Tests'
import ROBO from './ROBO/robo'
import ColorizeIcon from '@material-ui/icons/Colorize';
// import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import PostAddIcon from '@material-ui/icons/PostAddTwoTone';
import Summary from './PDFExport/Summary'

import Reports from './Gerry_UTF_Service/Reports'

import ShiftClosing from './ShiftClosing/ShiftClosing';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

import PhelebotomyCallUp from './Phelebotomy/PhelebotomyCallUp'

import AccessioningCallsUp from './Accessioning/AccessioningCallsUp'

import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';

import WorkList from './WorkList/WorkList'

import EditPatient from './EditPatient/CallUpPatient'

import Posting from './Posting/index'

import Payment from '@material-ui/icons/Payment';

// 22 Apr 2022
import SummaryInvoiceWise from './SummaryByInvoice/Summary.js'

// 23 Apr 2022
import SummaryBalance from './SummaryBalance/Summary.js'

// 27 Apr 2022
import ResultFeeding from './ResultFeeding/index'

//28 jan 2023
import PaymentReceipt from './PendingInvoice/index'
//9 feb 2023
import PaymentReceiptRecord from './PendingInvoiceRecord/index'
//18 apr 2023
import DocumentView from "./DocumentViewPanel/index"
//26 may 2023 
import Quotation from "./Quotation/Lab_Tests"
//06 oct 2023
import QuotationWithParameters from "./QuotationWithTestParam/Lab_Tests"



var summary= localStorage.getItem("franchise")=="true" ? [
  {
    path: '/Summary',
    name: 'Summary Sample Wise',
    type: 'link',
    icon: AssessmentIcon,
    component: Summary
  },
  {
    path: '/InvoiceWise',
    name: 'Summary Invoice Wise',
    type: 'link',
    icon: AssessmentIcon,
    component: SummaryInvoiceWise
  },
  {
    path: '/BalanceDueSummary',
    name: 'Summary Balance Due',
    type: 'link',
    icon: AssessmentIcon,
    component: SummaryBalance
  }
]
:[]


var posting= localStorage.getItem("balance")=="true" ? [
  {
    path: '/Posting',
    name: 'Posting',
    type: 'link',
    icon: Payment,
    component: Posting
  },
   {
          path: '/PaymentReceipt',
          name: 'Payment Receipt (Mutiple Invoices)',
          type: 'link',
          icon: FeaturedPlayListIcon,
          component: PaymentReceipt,
          
        },
        {
          path: '/InvoicePending',
          name: 'Payment Receipt Record',
          type: 'link',
          icon: FeaturedPlayListIcon,
          component: PaymentReceiptRecord
        },
]
:[]

var routing = localStorage.getItem('user_id') ?
  (localStorage.getItem('name') == "khizer" || localStorage.getItem('name').toLowerCase() == "umair" || localStorage.getItem('name').toLowerCase() == "urfa") ?
    [
      {
        path: '/',
        name: 'Invoice',
        type: 'link',
        icon: ReceiptIcon,
        component: Invoice
      },
      {
        path: '/AddPatient',
        name: 'Add Patient',
        type: 'link',
        icon: PersonAddIcon,
        component: AddPatient
      },
      {
        path: '/Invoices',
        name: 'Invoice Record',
        type: 'link',
        icon: ReceiptIcon,
        component: Lab_Tests
      }
      ,
      {
        path: '/GerrysUTFService',
        name: 'Audit Between Booking & Flight Date',
        type: 'link',
        icon: AssessmentIcon,
        component: Reports
      }
      ,
      {
        path: '/ShiftClosing',
        name: 'Shift Closing',
        type: 'link',
        icon: AccessTimeIcon,
        component: ShiftClosing
      }
      ,
      {
        path: '/Phelebotomy',
        name: 'Phelebotomy',
        type: 'link',
        icon: ColorizeIcon,
        component: PhelebotomyCallUp
      }
      ,
      {
        path: '/Accessioning',
        name: 'Accessioning',
        type: 'link',
        icon: FeaturedPlayListIcon,
        component: AccessioningCallsUp
      },
      
      

    ] : localStorage.getItem("org_branch_id") == "0"
      ?
      [
        {
          path: '/',
          name: 'Invoice',
          type: 'link',
          icon: ReceiptIcon,
          component: Invoice
        },
        {
          path: '/AddPatient',
          name: 'Add Patient',
          type: 'link',
          icon: PersonAddIcon,
          component: AddPatient
        },
        {
          path: '/Invoices',
          name: 'Invoice Record',
          type: 'link',
          icon: ReceiptIcon,
          component: Lab_Tests
        }
        // ,
    
        // {
        //   path: '/ROBO',
        //   name: 'BC ROBO 7',
        //   type: 'link',
        //   icon: DvrIcon,
        //   component: ROBO
        // }
        ,
        {
          path: '/Phelebotomy',
          name: 'Phelebotomy',
          type: 'link',
          icon: ColorizeIcon,
          component: PhelebotomyCallUp
        }
        ,
        {
          path: '/Accessioning',
          name: 'Accessioning',
          type: 'link',
          icon: FeaturedPlayListIcon,
          component: AccessioningCallsUp
        },
        {
          path: '/DocumentView',
          name: 'Document View',
          type: 'link',
          icon: PageviewOutlinedIcon,
          component: DocumentView
        },
        
        {
          path: '/ShiftClosing',
          name: 'Shift Closing',
          type: 'link',
          icon: AccessTimeIcon,
          component: ShiftClosing
        },
        {
          path: '/Quotation',
          name: 'Quotation',
          type: 'link',
          icon: PostAddIcon,
          component: Quotation
        }
        ,
        {
          path: '/QuotationWithParameters',
          name: 'Quotation With Test Parameters',
          type: 'link',
          icon: PostAddIcon,
          component: QuotationWithParameters
        }
        ,
        {
          path: '/EditPatient',
          name: 'EditPatient',
          type: 'link',
          icon: ColorizeIcon,
          component: EditPatient
        },
        // {
        //   path: '/ResultFeeding',
        //   name: 'Result Feeding',
        //   type: 'link',
        //   icon: FeaturedPlayListIcon,
        //   component: ResultFeeding
        // },
        ...posting
       
      ] :
      [
        {
          path: '/',
          name: 'Invoice',
          type: 'link',
          icon: ReceiptIcon,
          component: Invoice
        },
        {
          path: '/AddPatient',
          name: 'Add Patient',
          type: 'link',
          icon: PersonAddIcon,
          component: AddPatient
        },
        {
          path: '/Invoices',
          name: 'Invoice Record',
          type: 'link',
          icon: ReceiptIcon,
          component: Lab_Tests
        }
        ,
        {
          path: '/Phelebotomy',
          name: 'Phelebotomy',
          type: 'link',
          icon: ColorizeIcon,
          component: PhelebotomyCallUp
        }
        ,
        {
          path: '/ShiftClosing',
          name: 'Shift Closing',
          type: 'link',
          icon: AccessTimeIcon,
          component: ShiftClosing
        },
        {
          path: '/WorkList',
          name: 'Work List',
          type: 'link',
          icon: AssessmentIcon,
          component: WorkList
        },
        ...summary
        ,
        ...posting

      ]
  // {
  //   path: '/Radiology',
  //   name: 'Result Feed & Sign',
  //   type: 'link',
  //   icon: EditIcon,
  //   component: Radiology
  // }


  : []

export default {
  items: [
    ...routing
    // {
    //   path: '/home',
    //   name: 'Home',
    //   type: 'link',
    //   icon: ExploreIcon,
    //   component: Home
    // },
    // {
    //   path: '/apps',
    //   name: 'Apps',
    //   type: 'submenu',
    //   icon: AppsIcon,
    //   badge: {
    //     type: 'primary',
    //     value: '5'
    //   },
    //   children: [
    //     {
    //       path: '/calendar',
    //       name: 'Calendar',
    //       component: Calendar
    //     },
    //     {
    //       path: '/media',
    //       name: 'Media',
    //       component: Media
    //     },
    //     {
    //       path: '/messages',
    //       name: 'Messages',
    //       component: Messages
    //     },
    //     {
    //       path: '/social',
    //       name: 'Social',
    //       component: Social
    //     },
    //     {
    //       path: '/chat',
    //       name: 'Chat',
    //       component: Chat
    //     }
    //   ]
    // },
    // {
    //   path: '/widgets',
    //   name: 'Widgets',
    //   type: 'link',
    //   icon: PhotoIcon,
    //   component: Widgets
    // },
    // {
    //   path: '/material',
    //   name: 'Material',
    //   type: 'submenu',
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: 'error',
    //     value: '10'
    //   },
    //   children: [
    //     {
    //       path: '/appbar',
    //       name: 'App Bar',
    //       component: AppBar
    //     },
    //     {
    //       path: '/autocomplete',
    //       name: 'Auto Complete',
    //       component: Autocomplete
    //     },
    //     {
    //       path: '/avatars',
    //       name: 'Avatars',
    //       component: Avatars
    //     },
    //     {
    //       path: '/badges',
    //       name: 'Badges',
    //       component: Badges
    //     },
    //     {
    //       path: '/button-navigation',
    //       name: 'Button Navigation',
    //       component: ButtonNavigation
    //     },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons
    //     },
    //     {
    //       path: '/cards',
    //       name: 'Cards',
    //       component: Cards
    //     },
    //     {
    //       path: '/chips',
    //       name: 'Chips',
    //       component: Chips
    //     },
    //     {
    //       path: '/dialogs',
    //       name: 'Dialogs',
    //       component: Dialogs
    //     },
    //     {
    //       path: '/dividers',
    //       name: 'Dividers',
    //       component: Dividers
    //     },
    //     {
    //       path: '/drawers',
    //       name: 'Drawers',
    //       component: Drawers
    //     },
    //     {
    //       path: '/expansion-panels',
    //       name: 'Expansion Panels',
    //       component: ExpansionPanels
    //     },
    //     {
    //       path: '/grid-list',
    //       name: 'Grid List',
    //       component: GridList
    //     },
    //     {
    //       path: '/lists',
    //       name: 'Lists',
    //       component: Lists
    //     },
    //     {
    //       path: '/menus',
    //       name: 'Menus',
    //       component: Menus
    //     },
    //     {
    //       path: '/paper',
    //       name: 'Paper',
    //       component: Paper
    //     },
    //     {
    //       path: '/pickers',
    //       name: 'Pickers',
    //       component: Pickers
    //     },
    //     {
    //       path: '/progress',
    //       name: 'Progress',
    //       component: Progress
    //     },
    //     {
    //       path: '/selection-controls',
    //       name: 'Selection Controls',
    //       component: SelectionControls
    //     },
    //     {
    //       path: '/selects',
    //       name: 'Selects',
    //       component: Selects
    //     },
    //     {
    //       path: '/snackbars',
    //       name: 'Snackbars',
    //       component: Snackbars
    //     },
    //     {
    //       path: '/steppers',
    //       name: 'Steppers',
    //       component: Steppers
    //     },
    //     {
    //       path: '/tables',
    //       name: 'Tables',
    //       component: Tables
    //     },
    //     {
    //       path: '/tabs',
    //       name: 'Tabs',
    //       component: Tabs
    //     },
    //     {
    //       path: '/text-fields',
    //       name: 'Text Fields',
    //       component: TextFields
    //     },
    //     {
    //       path: '/tooltips',
    //       name: 'Tooltips',
    //       component: Tooltips
    //     }
    //   ]
    // },
    // {
    //   path: '/editor',
    //   name: 'Editor',
    //   type: 'link',
    //   icon: Looks3Icon,
    //   component: Editor
    // },
    // {
    //   path: '/ecommerce',
    //   name: 'Ecommerce',
    //   type: 'submenu',
    //   icon: Looks4Icon,
    //   badge: {
    //     type: 'secondary',
    //     value: 'N'
    //   },
    //   children: [
    //     {
    //       path: '/products',
    //       name: 'Products',
    //       component: Products
    //     },
    //     {
    //       path: '/detail',
    //       name: 'Detail',
    //       component: Detail
    //     }
    //   ]
    // },
    // {
    //   path: '/taskboard',
    //   name: 'Taskboard',
    //   type: 'link',
    //   icon: ViewColumnIcon,
    //   component: Taskboard
    // },
    // {
    //   path: '/charts',
    //   name: 'Charts',
    //   type: 'link',
    //   icon: ShowChartIcon,
    //   component: Charts
    // },
    // {
    //   path: '/maps',
    //   name: 'Maps',
    //   type: 'submenu',
    //   icon: NavigationIcon,
    //   children: [
    //     {
    //       path: '/google',
    //       name: 'Google',
    //       component: Google
    //     },
    //     {
    //       path: '/leaflet',
    //       name: 'Leaflet',
    //       component: Leaflet
    //     }
    //   ]
    // },
    // {
    //   path: '/pages',
    //   name: 'Pages',
    //   type: 'submenu',
    //   icon: PagesIcon,
    //   children: [
    //     {
    //       path: '/invoice',
    //       name: 'Invoice',
    //       component: Invoice
    //     },
    //     {
    //       path: '/timeline',
    //       name: 'Timeline',
    //       component: TimelinePage
    //     },
    //     {
    //       path: '/blank',
    //       name: 'Blank',
    //       component: Blank
    //     },
    //     {
    //       path: '/pricing',
    //       name: 'Pricing',
    //       component: PricingPage
    //     }
    //   ]
    // },
    // {
    //   name: 'Authentication',
    //   type: 'submenu',
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: '/signin',
    //       name: 'Signin',
    //       component: Signin
    //     },
    //     {
    //       path: '/signup',
    //       name: 'Signup',
    //       component: Signup
    //     },
    //     {
    //       path: '/forgot',
    //       name: 'Forgot',
    //       component: PasswordReset
    //     },
    //     {
    //       path: '/lockscreen',
    //       name: 'Lockscreen',
    //       component: Lockscreen
    //     }
    //   ]
    // },
    // {
    //   name: 'Error',
    //   type: 'submenu',
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: '/404',
    //       name: '404',
    //       component: NotFound
    //     },
    //     {
    //       path: '/500',
    //       name: 'Error',
    //       component: BackendError
    //     }
    //   ]
    // },
    // {
    //   path: 'https://iamnyasha.github.io/react-primer-docs/',
    //   name: 'Documentation',
    //   type: 'external',
    //   icon: LocalLibraryIcon
    // },
    // {
    //   path: 'https://primer.fusepx.com',
    //   name: 'Get Angular Version',
    //   type: 'external',
    //   icon: BookmarkIcon
    // }
  ]
};
