import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import ReactLoading from 'react-loading';

import TableContainer from '@material-ui/core/TableContainer';

import GridContainer from "../components/Grid/GridContainer.js";
import TableHead from '@material-ui/core/TableHead';

import { PDFViewer, Font } from '@react-pdf/renderer'
import Invoice from './Pdf/Invoice.js'

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";


// import GridItem from "components/Grid/GridItem.js";


import LibraryBooks from "@material-ui/icons/LibraryBooks";
import DialogActions from "@material-ui/core/DialogActions";

// import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";

import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import Modal from "@material-ui/core/Modal";
import MaterialTable from "material-table";





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'Test_Name', numeric: false, disablePadding: true, label: 'Test Name' },
  { id: 'Test_Code', numeric: false, disablePadding: true, label: 'Test Code' },
  { id: 'Sample_Type', numeric: false, disablePadding: false, label: 'Sample Type' },
  // 06-oct-2023 add special remarks
  { id: 'Comments', numeric: false, disablePadding: false, label: 'Special Remarks' },
  
  { id: 'Delivery_Time', numeric: false, disablePadding: false, label: 'Delivery Time' },
  { id: 'Test_Price', numeric: true, disablePadding: false, label: 'Test Price' },
  // { id: 'Sample', numeric: false, disablePadding: false, label: 'Sample' },

];
  {/* ye component hai jo niche call hua hai es ko props pass kiye hn header hai table ka  */}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // ye onSelectAllClick props me aaya hai
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: '#196c90',
        backgroundColor: lighten('#196c90', 0.85),
      }
      : {
        color: '#196c90',
        backgroundColor: '#196c90',
      },
  title: {
    flex: '1 1 100%',
  },
}));
var selected_LabTests = []

// ye componet table me call hota hai jab selected value >0 hoti hai tab table k opr show hota hai

const EnhancedTableToolbar = (props) => {

  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { Add_Tests_to_List } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <></>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Select">
          <Button
            color="primary"
            style={{ backgroundColor: '#196c90',color:"white" }}
            onClick={() => {
              Add_Tests_to_List()
            }}
          >
            Export List
                  </Button>
        </Tooltip>
      ) : (
          <></>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default class TestSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 'asc',
      setOrder: 'asc',
      selected: [],
      setSelected: [],
      orderBy: 'calories',
      setOrderBy: 'calories',
      page: 0,
      setPage: 0,
      dense: false,
      rows: [],
      org_rows: [],
      setDense: false,
      rowsPerPage: 10,
      setRowsPerPage: 10,
      alert: false,
      loading: true,

      //19 May 
      PanelList:[],
      Branches:[],
      AdminRight:false,
      branch:1,
      panel_code:"",
      discount:0,
      branchName:"Islamabad",
      panelName:"",
      ForName:"",
      //23-feb-2024 group call add 
      // for grouplist for collect all grouplist
      groupList:[],
      // for open /close  modal 
      openGroupCall:false,
      // for show and hide rowsbackup when its true while select group tirle from select option and false when click on close group call
      selectedGroupListShow:false,
  
        
       // selected groupList from groupList on click select to reserve prev how to add this this.setState({ rows select this one on control + f and jhan jhan ye aae ga same usi tran es ko copy kr dena jaisy rows me data aa rha hai es k sth this.setState({ rowsbackup: result })
       rowsbackup:[],
       // for save group name to show on  pdf 
       groupName:""
   


  



    }
    this.Add_Tests_to_List = this.Add_Tests_to_List.bind(this)
  }

  componentWillMount() {

    var vSearchStr={
      "User_ID" :localStorage.getItem('user_id'),
      "Menu_Option" :"Quotation Create"
  }
    fetch("http://192.168.1.120:8090/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res=>res.json()).then((res)=>{
      console.log(res)
      if(res[0].Option_Status=="Authorized"){
        this.setState({AdminRight:true})
      }else{
        // this.setState({AdminRight:false})
        alert("Sorry, You can't Authorized for this")
        window.location.href="http://192.168.18.98:3000/"

      }
     })

    fetch("http://192.168.1.120:8090/4DACTION/WebGetPriceListsBranchPanelS", {
      method: "POST",
      'Content-Type': "application/json",
      body: JSON.stringify({

        "branch_id": 1,
        "panel_code": ""

      })
    }
    ).then((res) => res.json())
      .then((result) => {
        console.log("Response of API 306 WebGetPriceListsBranchPanelS:",result)

        this.setState({ rows: result })
      //23-feb-2024 group call add 
        this.setState({ rowsbackup: result })


        fetch("http://192.168.1.120:8090/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
        .then((result) => {
          console.log("Response of API 315 WebPortal_GetAllPanelsList :",result)
          this.setState({PanelList : result})
        })

        fetch("http://192.168.1.120:8090/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
          this.setState({Branches : response})
          console.log("Response of API 321 BranchesLoadAllfromWebserver :",response)

    
        })

        this.setState({ org_rows: result })
        this.setState({ loading: false })
      })

  }
  Add_Tests_to_List() {
    this.setState({ alert: true })
  }

  emptyRows = () => this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage)
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({ setOrder: (isAsc ? 'desc' : 'asc') });
    this.setState({ setOrderBy: property })
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.rows.map((n) => n);
      this.setState({ selected: newSelecteds })
      selected_LabTests = newSelecteds
      return;
    }
    this.setState({ selected: [] })
  };
  classes = () => useStyles()

  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    selected_LabTests = newSelected
    this.setState({ selected: newSelected })

  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: (parseInt(event.target.value, 10)) });
    this.setState({ page: 0 })
  };

  handleChangeDense = (event) => {
    this.setState({ setDense: event.target.checked });
  };

  isSelected = (name) => {

    return this.state.selected.indexOf(name) != -1;
  }

  render() {
    return (
      <div className={this.classes.root}>
        {/* {this.state.loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          : */}
          <Paper className={this.classes.paper} >
             <div align="left" style={{
            display:"flex",
            flexDirection:'row',
            justifyContent:'space-between',
            // backgroundColor:"red",
            paddingTop:"2.5%",
            justifyContent:"center",
            alignItems:"center",
            fontWeight:"bold",
            fontSize:"150%"
          }}>
            Quotation
          </div>
           
            <div align="left" style={{
            display:"flex",
            flexDirection:'row',
            justifyContent:'space-between',
            paddingTop:"2.5%"

          }}>
            <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={3} >
              <TextField id="standard-basic" label="Search"
                style={{ marginLeft: '10%', width: '90%' }}
                
                onChange={async (e) => {
                  
                  var lab_tests = []
                  var text = e.target.value
                  // 26-feb-2024

                  if(this.state.selectedGroupListShow)
                  {
// yhan pe search from group lgani hai jo group k test hn agr kaha gya to 26-fev-2024
                  }
                  else{
                  var data = await this.state.org_rows.map((item) => {
                    if (item.Test_Name.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)

                    } else {
                      if (item.Test_Code.toLowerCase().includes(text.toLowerCase())) {
                        lab_tests.push(item)
                      }
                    }

                    return item

                  })
                  Promise.all(data).then((item) => {
                    this.setState({ rows: lab_tests })
      //23-feb-2024 group call add 
        this.setState({ rowsbackup: lab_tests })

                  })
                }
                }}
              />
              </Grid>
             
           
            <Grid item xs={6} sm={6} md={6} lg={3} >
             
                  <FormControl variant="filled" className={this.classes.formControl}  style={{width:"90%",marginLeft:"10%"}}>
                    <InputLabel htmlFor="filled-age-native-simple">Branch</InputLabel>
                    {/* <Select
                
                      native
                      value={this.state.branch+"||"+this.state.branchName}
                      onChange={(event) => {
                        const value = (event.target.value).split("||")[0];
                        const name = (event.target.value).split("||")[1];

                        console.log(event.target.value)

                        // Empty the Table
                        this.setState({ rows: [] })
      //23-feb-2024 group call add 

        this.setState({ rowsbackup: [] })

                        this.setState({ org_rows: [] })
                        

                        this.setState({branch : parseInt(value)})
                        this.setState({branchName : name})
                        this.setState({selected:[]})

                         // Set of Branches
                         fetch("http://192.168.1.120:8090/4DACTION/WebGetPriceListsBranchPanelS", {
                              method: "POST",
                              'Content-Type': "application/json",
                              body: JSON.stringify({

                                "branch_id": parseInt(value),
                                "panel_code": this.state.panel_code

                              })
                            }
                            ).then((res) => res.json())
                              .then((result) => {
                             
                                this.setState({ rows: result })
      //23-feb-2024 group call add 

        this.setState({ rowsbackup: result })

                                this.setState({ org_rows: result })

                          })

                      }}

                      style={{
                        color:"red",
                        fontWeight:"bold"
                      }}
                      inputProps={{
                        name: 'status',
                        id: 'filled-age-native-simple',
                      }}
                    >
                      {
                        this.state.Branches.map((item) => {
                          return <option value={item.BranchID}>{item.BranchName}</option>

                        })
                      }
                    </Select> */}
                       <Select
                
                native
                value={this.state.branch+"||"+this.state.branchName}
                onChange={(event) => {
                  const value = (event.target.value).split("||")[0];
                  const name = (event.target.value).split("||")[1];

                  console.log(event.target.value)

                  // Empty the Table
                  this.setState({ rows: [] })
                  this.setState({ org_rows: [] })
                  

                  this.setState({branch : parseInt(value)})
                  this.setState({branchName : name})
                  this.setState({selected:[]})

                   // Set of Branches
                   fetch("http://192.168.1.120:8090/4DACTION/WebGetPriceListsBranchPanelS", {
                        method: "POST",
                        'Content-Type': "application/json",
                        body: JSON.stringify({

                          "branch_id": parseInt(value),
                          "panel_code": this.state.panel_code

                        })
                      }
                      ).then((res) => res.json())
                        .then((result) => {
                       
                          this.setState({ rows: result })
                          this.setState({ org_rows: result })

                    })

                }}

                style={{
                  color:"red",
                  fontWeight:"bold"
                }}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                {
                  this.state.Branches.map((item) => {
                    return <option value={item.BranchID}>{item.BranchName}</option>

                  })
                }
              </Select>
                  </FormControl>
                 </Grid>
                  
<Grid item xs={6} sm={6} md={6} lg={3} >
                  
                  <FormControl variant="filled" className={this.classes.formControl} style={{width:"90%",marginLeft:"10%"}} >
                    <InputLabel htmlFor="filled-age-native-simple">Panel</InputLabel>
                  
                    <Select
                     style={{
                      color:"red",
                      fontWeight:"bold"
                    }}
                      native
                      value={this.state.panel_code+"||"+this.state.panelName}
                      onChange={(event) => {
                        this.setState({ loading: true })
                        this.setState({selected:[]})


                           // Empty the Table
                           this.setState({ rows: [] })
      //23-feb-2024 group call add 

        this.setState({ rowsbackup: [] })

                           this.setState({ org_rows: [] })
                        if(event.target.value=="||"){
                          this.setState({panel_code : ""})
                          this.setState({panelName : ""})
                          fetch("http://192.168.1.120:8090/4DACTION/WebGetPriceListsBranchPanelS", {
                            method: "POST",
                            'Content-Type': "application/json",
                            body: JSON.stringify({
  
                              "branch_id": parseInt(this.state.branch),
                              "panel_code": ""
  
                            })
                          }
                          ).then((res) => res.json())
                            .then((result) => {
                           console.log("WebGetPriceListsBranchPanelS response API 525",result)
                              this.setState({ rows: result })
      //23-feb-2024 group call add 

        this.setState({ rowsbackup: result })

                              this.setState({ org_rows: result })
                        this.setState({ loading: false })

                            })
                            return
                        }

                     

                        const value = (event.target.value).split("||")[0];
                        const name = (event.target.value).split("||")[1];

                        this.setState({panel_code : value})
                        this.setState({panelName : name})

                        this.setState({selected:[]})

                        // Set of Branches
                        fetch("http://192.168.1.120:8090/4DACTION/WebGetPriceListsBranchPanelS", {
                          method: "POST",
                          'Content-Type': "application/json",
                          body: JSON.stringify({

                            "branch_id": parseInt(this.state.branch),
                            "panel_code": value

                          })
                        }
                        ).then((res) => res.json())
                          .then((result) => {
                         console.log("check panel api response 561 "+JSON.stringify(result))
                            this.setState({ rows: result })
      //23-feb-2024 group call add 

        this.setState({ rowsbackup: result })

                            this.setState({ org_rows: result })
                        this.setState({ loading: false })

                          })

                      }}
                      inputProps={{
                        name: 'status',
                        id: 'filled-age-native-simple',
                      }}
                    >
                      {/* <option><div>
                         <TextField id="standard-basic" label="Search"
                style={{ marginLeft: '1em', width: '20em' }}
                onChange={async (e) => {
                  var lab_tests = []
                  var text = e.target.value
                  var data = await this.state.PanelList.map((item) => {
                    if (item.Panel_Name.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)

                    } else {
                      // if (item.Test_Code.toLowerCase().includes(text.toLowerCase())) {
                      //   lab_tests.push(item)
                      // }
                    }

                    return item

                  })
                  Promise.all(data).then((item) => {
                    this.setState({ PanelList: lab_tests })
                  })

                }}
              /></div> </option> */}
                      <option aria-label="Select" value="||" >None</option>
                    
                      {
                        this.state.PanelList.map((p) => {
                          return <option value={p.Panel_Code+"||"+p.Panel_Name}>{p.Panel_Name}</option>
                        })
                      }

                    </Select>
                  </FormControl>
                  
                 </Grid>
      {/* //23-feb-2024 group call add  */}

                 {this.state.selectedGroupListShow?
                  <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    // height: '50%',
                    marginTop: "1em",
                    marginLeft:"2em"
                  }}
                  onClick={()=>{
                    this.setState({ selectedGroupListShow: false})
    this.setState({ rows:this.state.rowsbackup })
    this.setState({ selected: [] })
    this.setState({ groupName: "" })
    this.setState({ openGroupCall: false})




                  }
                }
                    >
                      Close CALL A GROUP
                    </Button>
                 :
            <Grid item xs={6} sm={6} md={6} lg={3} >

                 <Button
                variant="contained"
                color="secondary"
                style={{
                  // height: '50%',
                  marginTop: "1em",
                  marginLeft:"2em"
                }}
                onClick={()=>{
                  this.setState({ openGroupCall: true})

                  // setGroupCall(false)
                  fetch("http://192.168.1.120:8090/4DACTION/WebGroupNamesCallingUp").then((res) => res.json()).then((response) => {
                    var arr=[]
                    console.log("Invoice when click Call a group first Api response 2605 : ",response)
                    response.map((group)=>{
                      //es arr.push me group ki value plus select ka aik button hai 9 item hn reponse me 9 obj bny ge aur arr ki array me dal deny hn aur map k bahir ye arr groupArry ko pass ho jae gi 2818 aur groupArray ki value true ho jae gi
                      arr.push({
                        // es ...group se jo Api ka response aaya hai jis me groupId,groupName,PanelCode,panelName ye arr me push hoga 
                        ...group,
                        // ye button 2813 me end ho rha hai 
                        select: <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          height: '50%'
                        }}
                        onClick={()=>{
                          // alert("hy")
                          var obj={
                            group_id:group.group_id,
                            branch_id:parseInt(localStorage.getItem("branch_id")),
                            panel_code:group.panel_code
                        }
                        fetch("http://192.168.1.120:8090/4DACTION/WebGroupTestsPricesCallingUp",
                        {
                               method:"POST",
                        'Content-Type':"application/json",
                        body: JSON.stringify(obj)
                        }
                        ).then((res) => res.json()).then((response) => {
                          // console.log("Invoice Select button that are in Call agroup API response :",response)

                          //save group name 
    this.setState({ groupName: group.group_name})

                          

                          var TestBox=[]

                          JSON.parse(response.ArrayTestsCode).map((row,index)=>{
                            TestBox.push({
                              //value almost wohi hn jo response se aa rhi hn array me hn one by one chahye so that map lgaya and in the base of index hum value utha k textBox me dalty jaen ge
                              //Json.parse json.stringify ka inverse hai hum data JSON.stringify me dety hn us ko dobara json me krny k lye json.parse use krty hn 
                              // AllowDiscountPercent:JSON.parse(response.ArrayTestsCode)[index],
                              // Delivery_Date:JSON.parse(response.ArrayDelivery_Date)[index],
                              // Delivery_Time:JSON.parse(response.ArrayDelivery_Time)[index],
                              // ArrayGroupPriceNormal:JSON.parse(response.ArrayGroupPriceNormal)[index],
                              // Test_Price:JSON.parse(response.ArrayGroupPriceOffered)[index],
                              Test_Code:row,
                              // Test_ID:JSON.parse(response.ArrayTestsID)[index],
                              // Test_Name:JSON.parse(response.ArrayTestsName)[index],
                              // ArrayTestsPricefromDB:JSON.parse(response.ArrayTestsPricefromDB)[index],
                              // group_id:response.group_id,
                              // group_name:response.group_name

                            })

                            
                          })
                        //   // response.ArrayTestsCode map end
                          console.log("Invoice Select in Call A group TestBox filled after array :",TestBox)
    const comparisonResults = [];

    // Compare each Test_Code in data with findDataTestCodes
    TestBox.forEach(item => {
      // console.log("check ")
      // console.log(item)

      const match = this.state.rows.find(code => code.Test_Code == item.Test_Code);
      if (match) {
        // comparisonResults.push(`Match found for Test_Code: ${item.Test_Code}`);
        console.log("yes match")
        console.log(match)
        comparisonResults.push(match);


      } else {
        console.log("not match")
        // comparisonResults.push(`No match found for Test_Code: ${item.Test_Code}`);
      }
    });
    console.log("comparisonResults")
    console.log(comparisonResults)
    // 24-feb-2024
    this.setState({ rows: comparisonResults })
    this.setState({ openGroupCall: false})
    // add this
    // this.setState({ selectedGroupList:comparisonResults})

    this.setState({ selectedGroupListShow:true})
   })
    




                        }}
                        >Select</Button>

                      })
                    })
                    console.log("Invoice All value added in arr array in call a group test Api :",arr)
                    this.setState({groupList : arr}) 
                    this.setState({ openGroupCall: true})


                    
                    // setGroupList(arr)
                    // setGroupCall(true)
                    
                  })

                  
                }}
                >Call a Group</Button>
                </Grid>
                }
<Grid item xs={6} sm={6} md={6} lg={3} >

                  {
                    this.state.panelName==""
                    ?
                    
                
                
                  <TextField id="standard-basic" label="Discount %"
                  style={{width:"90%",marginLeft:"10%"}}
                    type="number"
                    min="0" max="100"
                    value={this.state.discount}
                    onChange={async (e) => {
                      {if(e.target.value<0)
                        return
                      }
                      if(e.target.value>100){
                        this.setState({discount : 0}) 
                      }else{
                        this.setState({discount : e.target.value})
                      }
                    }}
                  />
              
                  :
                  null
                  }
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} >
{
                    this.state.panelName==""
                    ?
                    
                 
<TextField  label="Issue For "
                    style={{
                      width:"90%",
                       marginLeft: '10%',
                    //  width: '15em'
                  //  marginRight: '1em',

                     }}
                    type="text"
                    // min="0" max="100"
                    value={this.state.ForName}
                    onChange={async (e) => {
                     
                        this.setState({ForName : e.target.value})
                     
                    }}
                  />
                  :
                  null
                  }
</Grid>
          
            </Grid>
            </div>
            {/* EnhancedTableToolbar ye oper bna hua hai as a component jab selected value >0 hogi tab ye table k opr show hoga jis me export ka button hai  */}
            <EnhancedTableToolbar numSelected={this.state.selected.length} Add_Tests_to_List={this.Add_Tests_to_List} />
            <TableContainer>
            {this.state.loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
              <Table
                className={this.classes.table}
                aria-labelledby="tableTitle"
                size={this.state.dense ? 'small' : 'medium'}
                aria-label="enhanced table"

              >
                <EnhancedTableHead
                  classes={this.classes}
                  numSelected={this.state.selected.length}
                  //order is asc 
                  order={this.state.order}
                  //orderBy is calories
                  orderBy={this.state.orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}

                  rowCount={this.state.rows.length}
                />
                <TableBody>
                  {stableSort(this.state.rows, getComparator(this.state.order, this.state.orderBy))
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => this.handleClick(event, row)}
                          role="checkbox"
                          aria-checked={this.isSelected(row)}
                          tabIndex={-1}

                          key={row.Test_Name}
                          selected={this.isSelected(row)}
                        >
                          <TableCell padding="checkbox"
                          onClick={(event) => this.handleClick(event, row)}
                           >
                            <Checkbox
                              checked={this.isSelected(row)}
                              inputProps={{ 'aria-label-ledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.Test_Name}
                          </TableCell>
                          <TableCell align="left">{row.Test_Code}</TableCell>
                          <TableCell align="left">{row.Sample_Type}</TableCell>
                          <TableCell align="left">{row.special_comments}</TableCell>

                          <TableCell align="left">{row.Delivery_Time}</TableCell>
                          <TableCell align="right">{row.Test_Price + " Rs"}</TableCell>
                          

                        </TableRow>

                      );
                    })}

                   {this.emptyRows() > 0 && (
                    <TableRow style={{ height: (this.state.dense ? 33 : 53) * this.emptyRows() }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
  }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.rows.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        {/* } */}
        <GridContainer>
          <Dialog
            classes={{
              root: this.classes.center,
              paper: this.classes.modal
            }}
            open={this.state.alert}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
            fullWidth={true}
            onClose={() => this.setState({ alert: false })}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={this.classes.modalHeader}
            >
              <h3><b>Quotation</b></h3>

            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={this.classes.modalBody}

            >
              <center>
                {
                  this.state.selected.length>0
                  ?
                  <Fragment>
                  <PDFViewer width="1000" height="1000" className="app" >
                    <Invoice invoice={this.state.selected} discount={this.state.discount} branchName={this.state.branchName} panelName={this.state.panelName} forName={this.state.ForName} groupName={this.state.groupName} />
                  </PDFViewer>
                </Fragment>
                :
                null

                }
             
              </center>
            </DialogContent>
            <DialogActions className={this.classes.modalFooter}>

              <Button
                onClick={() => this.setState({ alert: false })}
                color="danger"
                simple
              >
                <b>Close</b>
              </Button>
            </DialogActions>
          </Dialog>
        </GridContainer>
        <GridContainer>
           {/* Test Selection in group call */}
           {/* 23-feb-2024 group call add */}
      <Modal
        open={this.state.openGroupCall}
        onClose={()=>{
          // setGroupCall(false)
          this.setState({ openGroupCall:false })

        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div  style={{
          width:500,
          // width: 1200,
          backgroundColor:"green",
          border: "2px solid #000",
          // boxShadow: theme.shadows[5],
          margin: 'auto',
          // padding: theme.spacing(2, 4, 3),
        }}>
          <MaterialTable
            options={{
              maxBodyHeight: '30em'
            }}
            columns={[
              { field: "group_name", title: "Group Name" },
              { field: "select", title: "Select" },
              // jo field me name likhen ge wo data me jo array dali hai us k sth match hota hai srf wohi display hota hai jo name field me aur groupList me hai wo nazar aae gi
              
            ]}
            data={this.state.groupList}
            title={"Call a Group"}
          />
        </div>
      </Modal>
        </GridContainer>

      </div>
    );
  }
}
